<template>
  <div class="container">
    <div class="content_area">
      <div class="search">
        职位筛选：
        <el-select
          v-model="value"
          @change="changeType"
          size="small"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in positionTypeList"
            :key="item.value"
            :label="item.label_zh"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="list" v-loading="loading">
        <noDataImg v-if="!list.length"></noDataImg>
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          :style="(index + 1) % 3 == 0 ? 'margin-right:0;' : ''"
        >
          <div class="msg">
            <div class="headImg">
              <el-avatar
                shape="circle"
                :size="46"
                style="margin-right: 10px"
                :fit="'contain'"
                :src="
                  item.avatar
                    ? item.avatar
                    : 'https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/grounp.png'
                "
              ></el-avatar>
              <div class="persomMsg">
                <div class="msgOne" style="padding-bottom: 6px">
                  <p @click="show(item)" class="name">
                    {{
                      item.user_name_en | priorFormat(item.user_name_zh, LOCALE)
                    }}
                  </p>
                  <span class="font-12">
                    发布于{{ item.created_time | secondFormat("L") }}</span
                  >
                </div>
                <div class="msgtwo" style="font-size: 14px; color: #666">
                  <p>
                    <template v-if="item.sex == 2">女</template>
                    <template v-if="item.sex == 1">男</template> |
                    {{ item.age }}岁 |
                    {{ item.level | levelRequireFormat(LOCALE) }}
                  </p>

                  <!-- <p>
                    期望薪资：{{ item.salary_lowest }}-{{
                      item.salary_highest
                    }}K
                  </p> -->
                </div>
              </div>
            </div>
            <div class="btn" v-if="inspectRouteIvm">
              <orderTalk
                :meeting_id="60"
                :in_userId="item.user_id"
                :options="options"
                invitationType="2"
              >
                <el-button size="small" type="primary" slot="btn"
                  >约面试</el-button
                >
              </orderTalk>
            </div>
          </div>
          <div style="margin-top: 15px">
            <p style="margin: 5px 0">
              期望职位：
              <span v-for="(it, index) in item.position_type" :key="index">
                {{ it | positionTypeListFormat(LOCALE) }}
              </span>
            </p>
            <div class="desc">
              {{ item.advantage }}
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <el-pagination
          class="fr"
          style="margin-top: 20px"
          @current-change="pageChange"
          background
          layout="prev, pager, next"
          :page-size="limit"
          :current-page="start"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="查看简历"
      :visible.sync="previewResumeDialog"
      width="800px"
    >
      <myInfomation
        v-if="previewResumeDialog"
        :resumeInfo="form"
      ></myInfomation>
    </el-dialog>
  </div>
</template>

<script>
import myInfomation from "~xif/views/talents/myInfomation";
import orderTalk from "~xif/views/talents/orderTalk"
import { positionTypeList } from "~xif/utils/enumValue";
export default {
  metaInfo: {
    title: '厦门货代协会--人才库'
  },
  components: { myInfomation, orderTalk },
  data() {
    return {
      value: "",
      options: [],
      positionTypeList,
      list: [],
      limit: 9,
      start: 1,
      count: 0,
      type: "",
      previewResumeDialog: false,
      form: {},
      loading: false,
    };
  },
  computed: {
    inspectRouteIvm() {
      return this.$store.state.baseStore.auth.MENUAUTH.indexOf("tac:ivm") != -1;
    },
  },
  mounted() {
    this.getSearchList();
    this.getTalentsList();
  },
  methods: {
    show(e) {
      this.getMyresume(e.user_id);
    },
    async getMyresume(id) {
      let data = await this.$store.dispatch("API_user/user_getMyresume", {
        user_id: id,
      });

      let info = data.data;

      // info.user_info.birthday =new Date(this.$moment(info.user_info.birthday*1000).format('YYYY-MM-DD'))
      info.user_info.birthday = this.$moment(
        info.user_info.birthday * 1000
      ).format("YYYY-MM-DD");

      // info.educational_experience_info =  info.education_experience_info
      info.work_experience_info.forEach((ee, vv) => {
        if (ee.position_type == 0) {
          ee.position_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times[0] = new Date(
            this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
          );
          ee.times[1] = new Date(
            this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
          );
        } else {
          ee.times = "";
        }
      });
      info.educational_experience_info.forEach((ee, vv) => {
        if (ee.level == 0) {
          ee.level = "";
        }
        if (ee.class_type == 0) {
          ee.class_type = "";
        }
        if (ee.start_time) {
          ee.times = [];
          ee.times[0] = new Date(
            this.$moment(ee.start_time * 1000).format("YYYY-MM-DD")
          );
          ee.times[1] = new Date(
            this.$moment(ee.end_time * 1000).format("YYYY-MM-DD")
          );
        } else {
          ee.times = "";
        }
      });
      this.form = info;
      this.form.user_info.birthday = this.$moment(
        this.form.user_info.birthday
      ).unix();
      let num = this.$moment().unix() - this.form.user_info.birthday;
      this.form.user_info.age = Math.ceil(num / 31536000);
      this.previewResumeDialog = true;
    },
    changeType(e) {
      if (e) {
        this.positionTypeList.forEach((ee) => {
          if (ee.value == e) {
            this.type = ee.value;
          }
        });
      } else {
        this.type = "";
      }
      this.start = 1;
      this.getTalentsList();
    },
    pageChange(e) {
      this.start = e;
      this.getTalentsList();
    },
    async getTalentsList() {
      this.loading = true;
      let parmas = {
        nopage: 0,
        start: this.start - 1,
        limit: this.limit,
        source: this.PJSource,
      };
      if (this.type) {
        parmas.position_type = this.type;
      }
      let res = await this.$store.dispatch(
        "API_position/getTalentsList",
        parmas
      );
      if (res && res.success) {
        res.data.forEach((element) => {
          element.position_type = element.position_type.split(",");
          let num = this.$moment().unix() - element.birthday;
          element.age = Math.floor(num / 31536000);
        });
        this.list = res.data;
        this.count = res.count;
      }
      this.loading = false;
    },
    async getSearchList() {
      let params = {
        company_id: this.USER_INFO.company_id,
        nopage: 1,
        status: 3,
      };
      params.start = 0;
      params.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_position/getJobPositionList",
        params
      );
      this.options = res.data;
    },
  },
};
</script>

<style scoped lang="less">
.search {
  border-bottom: 1px solid #e8e8e8;
  padding: 15px;
  background: #fff;
}
.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  min-height: 464px;
  overflow: auto;
  position: relative;
  .item {
    width: 384px;
    height: 208px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 1px 7px 0px rgba(91, 111, 137, 0.2);
    padding: 0 18px;
    margin-top: 24px;
    margin-right: 24px;
    .msg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px dashed #ececec;
      .headImg {
        display: flex;
        align-items: center;
        .msgOne {
          display: flex;
          align-items: flex-end;
          .name {
            font-size: 16px;
            font-weight: 400;
            margin-right: 10px;
            cursor: pointer;
            color: #16407a;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .msgtwo {
          display: flex;
          align-items: center;
        }
      }
    }
    .desc {
      font-size: 12px;
      color: #9b9b9b;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.font-12 {
  font-size: 12px;
  color: #9b9b9b;
}
.container {
  padding: 30px 0;
  background: #f2f4f6;
}

.pagination {
  margin: 20px auto 60px;
  display: flex;
  flex-direction: row-reverse;
}
</style>